<template>
  <div class="hero-billboard">
    <div class="hero-billboard__background" :class="{ '_darkened': darkened }">
      <img class="hero-billboard__background-image"
           :src="require(`@/assets/images/${params.src}`)"
           alt/>
    </div>
    <div class="hero-billboard__wrapper">
      <Column>
        <Row class="trips-view__navigation">
          <router-link :key="item.id"
                       :to="item.href"
                       class="trips-view__navigation-link"
                       v-for="item in params.navigation">{{item.title}}
          </router-link>
        </Row>
        <div class="hero-billboard__info">
          <h1 class="hero-billboard__title">{{title}}</h1>
          <p class="hero-billboard__description" v-html="description"></p>
        </div>
        <Row class="hero-billboard__meta"
             justify="between">
          <Column :key="item.id"
                  class="hero-billboard__column"
                  v-for="item in params.meta">
            <Row>
              <Icon :xlink="item.xlink"
                    class="hero-billboard__icon"
                    viewport="0 0 62 62"/>
              <Column>
                <span class="hero-billboard__suptitle">{{item.suptitle}}</span>
                <span class="hero-billboard__subtitle">{{item.subtitle}}</span>
              </Column>
            </Row>
          </Column>
        </Row>
      </Column>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeroBillboard',
  props: {
    title: {},
    description: {},
    darkened: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
